<template>
  <div id="addWaterSupply">
    <b-modal
      id="modal-center"
      v-model="showModal"
      modal-class="custom-modal-defualt"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      size="xl"
      @change="onChange"
      @shown="show"
      @ok="ok"
    >
      <template #modal-title="{}">
        <font-awesome-icon
          v-if="isEdit === false"
          :icon="['fas', 'plus-circle']"
          class="mr-1"
        />
        <font-awesome-icon v-else :icon="['fas', 'edit']" class="mr-1" />
        {{ headerText }}
      </template>

      <template v-slot:default="">
        <b-overlay :show="isLoad">
          <div class="d-flex headline-list">
            <div class="py-2 weight-700">แหล่งข้อมูลประปา</div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">จังหวัด :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="provinceList"
                    v-model="province"
                    @change="getAmphor($event)"
                    placeholder="จังหวัด"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">อำเภอ :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="amphorList"
                    v-model="amphor"
                    @change="getTambol($event)"
                    placeholder="อำเภอ"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ตำบล :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="tambolList"
                    v-model="tambol"
                    @change="getVillage($event)"
                    placeholder="ตำบล"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="d-flex align-items-center">
                <div class="col-6 text-right px-0">หมู่ที่ :</div>
                <div class="col-6 pr-0">
                  <b-form-input
                    class="input-custom w-100"
                    placeholder="หมู่ที่"
                    v-model="villageNo"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ชื่อหมู่บ้าน / ชุมชน :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="villageList"
                    v-model="village"
                    placeholder="ชื่อหมู่บ้าน / ชุมชน"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">อปท. :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom w-100"
                    placeholder="อปท."
                    v-model="supplyName"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="d-flex headline-list">
              <div class="py-2">
                <span class="weight-700"> สถานที่ตั้ง </span>
                <span class="font-gray"
                  >(สามารถเลือกกำหนดค่าอย่างใดอย่างหนึ่ง)</span
                >
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="blue-box">
                <div class="header weight-700">ค่า UTM</div>
                <div class="content">
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">โซน :</div>
                        <div class="col-6">
                          <select2
                            class="select-custom"
                            :options="zoneList"
                            v-model="zone"
                            placeholder="โซน"
                            :settings="{
                              language: {
                                noResults: function () {
                                  return 'ไม่พบข้อมูล';
                                },
                              },
                              allowClear: 'true',
                            }"
                          >
                          </select2>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">
                          พิกัดหมู่บ้าน X :
                        </div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="พิกัดหมู่บ้าน X"
                            v-model="UTME"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">
                          พิกัดหมู่บ้าน Y :
                        </div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="พิกัดหมู่บ้าน Y"
                            v-model="UTMN"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <div class="blue-box">
                <div class="header weight-700">ละติจูด / ลองจิจูด</div>
                <div class="content">
                  <div class="row mt-3">
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">ละติจูด :</div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="ละติจูด"
                            v-model="Latitude"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                    <div class="col-4">
                      <div class="row d-flex align-items-center">
                        <div class="col-6 text-right px-0">ลองจิจูด :</div>
                        <div class="col-6">
                          <b-form-input
                            class="input-custom"
                            placeholder="ลองจิจูด"
                            v-model="Longitude"
                            type="number"
                          ></b-form-input>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5">
            <div class="d-flex headline-list">
              <div class="py-2">
                <span class="weight-700"> ข้อมูลทั่วไป </span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">ประเภท / ขนาด :</div>
                <div class="col-6">
                  <select2
                    class="select-custom"
                    :options="sourceTypeList"
                    v-model="sourceTypeSelect"
                    placeholder="ประเภท / ขนาด"
                    :settings="{
                      language: {
                        noResults: function () {
                          return 'ไม่พบข้อมูล';
                        },
                      },
                      allowClear: 'true',
                    }"
                  >
                  </select2>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">
                  เจ้าของกรรมสิทธิ์ทรัพย์สิน :
                </div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="เจ้าของกรรมสิทธิ์ทรัพย์สิน"
                    v-model="Owner"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">อัตราการผลิต :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="อัตราการผลิต"
                    v-model="Capacity"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">จำนวนครัวเรือนรับน้ำ :</div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="จำนวนครัวเรือนรับน้ำ"
                    v-model="HouseholdReceivingWater"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="row d-flex align-items-center">
                <div class="col-6 text-right px-0">
                  ปี พ.ศ. ที่ก่อสร้างเสร็จ :
                </div>
                <div class="col-6">
                  <b-form-input
                    class="input-custom"
                    placeholder="ปี พศ ที่ก่อสร้างเสร็จ"
                    v-model="CreateYear"
                    type="number"
                  ></b-form-input>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-2 px-0">
              <div class="text-right">อัพโหลดภาพถ่าย :</div>
            </div>
            <div class="col">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <button
                    class="btn btn-check d-flex align-items-center mr-2"
                    @click="chooseFiles()"
                  >
                    <font-awesome-icon :icon="['fas', 'upload']" class="mr-1" />
                    อัพโหลดภาพถ่าย
                  </button>

                  <span class="font-gray"
                    >*รองรับไฟล์เอกสารประเภท .jpeg .png เท่านั้น</span
                  >
                  <input
                    ref="fileUpload"
                    type="file"
                    @change="(val) => getFiles(val)"
                    hidden
                  />
                  <div class="pl-2" v-show="isHaveFile">
                    <span
                      class="cursor-pointer font-blueinfo"
                      style="text-decoration: underline"
                      @click="downloadFile()"
                      >(ดาวน์โหลดไฟล์แนบ)</span
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-2"></div>
            <div class="col">{{ fileName }}</div>
          </div>
        </b-overlay>
      </template>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex w-100 justify-content-center my-2">
          <button class="btn btn-outline-save" @click="cancel()">ยกเลิก</button>
          <div class="mx-5"></div>
          <button type="submit" class="btn btn-save" @click="ok()">
            บันทึก
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { FilterService, WaterSupplyService } from "@/services/main.service.js";
export default {
  props: {
    isEdit: {
      type: Boolean,
      default: () => false,
    },
    modalShow: {
      type: Boolean,
      default: () => false,
    },
    pwaId: {
      type: Number,
      default: () => null,
    },
  },
  data() {
    return {
      showModal: false,
      headerText: "",
      provinceList: [],
      province: null,
      amphorList: [],
      amphor: null,
      tambolList: [],
      tambol: null,
      villageList: [],
      village: null,
      villageNo: null,
      supplyName: null,
      zoneList: [],
      zone: null,
      Latitude: null,
      Longitude: null,
      UTME: null,
      UTMN: null,
      sourceTypeList: [],
      sourceTypeSelect: null,
      Owner: null,
      Capacity: null,
      HouseholdReceivingWater: null,
      CreateYear: null,
      ProjectPicture: null,
      fileName: null,
      isHaveFile: false,
      apiURL: null,
      isLoad: false,
    };
  },
  created() {
    this.getSourceTypeList();
    this.apiURL = process.env.VUE_APP_BASE_API_DOWNLOAD;
  },
  methods: {
    async getSourceTypeList() {
      this.sourceTypeList = [];
      this.sourceTypeSelect = null;
      await FilterService.getFilterSourceType()
        .then((res) => {
          this.sourceTypeList = res;
        })
        .catch((err) => {
          this.sourceTypeList = [];
        });
    },
    chooseFiles() {
      this.$refs["fileUpload"].click();
    },
    getFiles(val) {
      const objFile = val.target.files[0];

      if (objFile) {
        this.ProjectPicture = objFile;

        this.fileName = objFile.name;
      }
    },
    downloadFile() {
      let file = `${this.apiURL}/Uploads/${this.ProjectPicture}`;
      try {
        window.open(file, "_blank");
      } catch (e) {
        this.alertDownloadFail();
      }
    },
    show() {
      const el = document.querySelector("#modal-center");
      const test = el.querySelectorAll(".modal-content");
      test[0].removeAttribute("tabindex");
    },
    onChange() {
      if (!this.showModal) {
        this.provinceList = [];
        this.province = null;
        this.amphorList = [];
        this.amphor = null;
        this.tambolList = [];
        this.tambol = null;
        this.villageList = [];
        this.village = null;
        this.villageNo = null;
        this.supplyName = null;

        this.zoneList = [];
        this.zone = null;
        this.Latitude = null;
        this.Longitude = null;
        this.UTME = null;
        this.UTMN = null;

        this.sourceTypeSelect = null;
        this.Owner = null;
        this.Capacity = null;
        this.HouseholdReceivingWater = null;
        this.CreateYear = null;
        this.ProjectPicture = null;

        this.fileName = null;
        this.isHaveFile = false;
        this.$emit("change", this.showModal);
      }
    },
    async ok(bvModalEvt) {
      bvModalEvt.preventDefault();
      if (this.province === null) {
        this.alertFormValidate();
        return false;
      }
      if (this.amphor === null) {
        this.alertFormValidate();
        return false;
      }
      if (this.tambol === null) {
        this.alertFormValidate();
        return false;
      }
      if (this.village === null) {
        this.alertFormValidate();
        return false;
      }
      if (this.villageNo === null) {
        this.alertFormValidate();
        return false;
      }
      if (this.supplyName === null) {
        this.alertFormValidate();
        return false;
      }

      if (this.zone == null && this.UTME === null && this.UTMN === null) {
        if (this.Latitude === null && this.Longitude === null) {
          this.alertFormValidate();
          return;
        }
      }

      let formData = new FormData();

      let tmp_zone = null;
      if (this.zone !== null) {
        tmp_zone = this.zoneList.filter((f) => {
          return f.id === parseInt(this.zone);
        })[0]?.text;
      }
      this.isLoad = true;

      formData.append("ProvinceId", this.province || "");
      formData.append("AmphorId", this.amphor || "");
      formData.append("TambolId", this.tambol || "");
      formData.append("VillageNo", this.villageNo || "");
      formData.append("VillageId", this.village || "");
      formData.append("Name", this.supplyName || "");

      formData.append("UTMZone", tmp_zone || "");
      formData.append("UTME", this.UTME || "");
      formData.append("UTMN", this.UTMN || "");
      formData.append("Latitude", this.Latitude || "");
      formData.append("Longitude", this.Longitude || "");

      formData.append("PWAProjectTypeId", 1);
      formData.append("PWASourceTypeId", this.sourceTypeSelect || "");
      formData.append("Owner", this.Owner || "");
      formData.append("Capacity", this.Capacity || "");
      formData.append(
        "HouseholdReceivingWater",
        this.HouseholdReceivingWater || ""
      );
      formData.append("CreateYear", this.CreateYear || "");
      formData.append("ProjectPicture", this.ProjectPicture || "");

      if (this.isEdit) {
        formData.append("PWAProjectId", this.pwaId || "");

        await WaterSupplyService.editWater(formData)
          .then((res) => {
            if (res.resData.status) {
              this.isLoad = false;
              this.alertSuccess("แก้ไขข้อมูลประปาหมู่บ้านสำเร็จ").then(
                (result) => {
                  this.showModal = false;
                  this.onChange();
                  this.$emit("reloadDataGrid");
                }
              );
            }
          })
          .catch((err) => {
            this.isLoad = false;
            this.alertFail("แก้ไขข้อมูลไม่สำเร็จ");
          });
      } else {
        await WaterSupplyService.InsertWater(formData)
          .then((res) => {
            if (res.resData.status) {
              this.isLoad = false;
              this.alertSuccess("เพิ่มข้อมูลประปาหมู่บ้านสำเร็จ").then(
                (result) => {
                  this.showModal = false;
                  this.onChange();
                  this.$emit("reloadDataGrid");
                }
              );
            }
          })
          .catch((err) => {
            this.isLoad = false;
            this.alertFail("เพิ่มข้อมูลไม่สำเร็จ");
          });
      }
    },
    cancel() {},
    async getProvince() {
      await FilterService.getListProvince()
        .then((res) => {
          this.provinceList = res?.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.provinceList = [];
        });
    },
    async getAmphor(provinceId = null) {
      this.amphorList = [];
      this.amphor = null;
      this.tambolList = [];
      this.tambol = null;
      this.villageList = [];
      this.village = null;
      await FilterService.getListAmphor(provinceId)
        .then((res) => {
          this.amphorList = res?.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.amphorList = [];
        });
    },
    async getTambol(amphorId = null) {
      this.tambol = null;
      this.tambolList = [];
      this.villageList = [];
      this.village = null;
      await FilterService.getListTambol(amphorId)
        .then((res) => {
          this.tambolList = res?.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.tambolList = [];
        });
    },
    async getVillage(tambolId = null) {
      this.village = null;
      this.villageList = [];
      await FilterService.getListVillage(tambolId)
        .then((res) => {
          this.villageList = res?.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.villageList = [];
        });
    },
    async getZone() {
      await FilterService.getListZone()
        .then((res) => {
          this.zoneList = res?.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.zoneList = [];
        });
    },
  },
  watch: {
    modalShow: {
      handler: function (val, oldVal) {
        if (val) {
          this.getProvince();
          this.getZone();
          this.headerText = "เพิ่มข้อมูลประปาหมู่บ้าน";

          if (this.isEdit) {
            if (this.pwaId !== null) {
              this.headerText = "แก้ไขข้อมูลประปาหมู่บ้าน";
              WaterSupplyService.getWaterByID(this.pwaId).then((res) => {
                this.province = res.provinceId;
                this.getAmphor(this.province);
                this.amphor = res.amphorId;
                this.getTambol(this.amphor);
                this.tambol = res.tamBolID;
                this.getVillage(this.tambol);
                this.village = res.villageId;
                this.villageNo = res.villageNo;
                this.supplyName = res.name;
                const zone_tmp =
                  this.zoneList.filter((f) => {
                    return f.text === res.utmZone;
                  })[0] || {};
                this.zone = zone_tmp?.id || null;
                this.Latitude = res.latitude;
                this.Longitude = res.longitude;
                this.UTME = res.utme;
                this.UTMN = res.utmn;

                this.sourceTypeSelect = res.sourceTypeID;
                this.Owner = res.owner;
                this.Capacity = res.capacity;
                this.HouseholdReceivingWater = res.householdReceivingWater;
                this.CreateYear = res.createYear;

                if (res.projectPicture !== "" && res.projectPicture !== null) {
                  this.ProjectPicture = res.projectPicture;
                  this.isHaveFile = true;
                } else {
                  this.ProjectPicture = null;
                  this.isHaveFile = false;
                }
              });
            }
          }
          this.showModal = true;
        }
      },
    },
    deep: true,
  },
};
</script>

<style></style>
